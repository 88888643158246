.Faucet {
 background-color: #F5FAFF;
 display: flex;
 flex-direction: column;
 height: 100vh;
}

.Faucet-header {
 align-items: flex-start;
}

.Faucet-logo {
 height: 32px;
 margin-top: 32px;
 margin-left: 40px;
 flex: 1;
}

.Faucet-body {
 flex: 1;
 display: flex;
 flex-direction: column;
 align-items: center;
}

.Card-panel {
 width: 40vw;
 flex: 0 1 auto;
 box-shadow: 0px 0px 30px rgba(39, 52, 133, 0.05);
 border-radius: 14px;
 background-color: #FFF;
 padding: 48px;
 display: flex;
 flex-direction: column;
 align-items: stretch;
 margin-bottom: 56px;
}

.Panel-title {
 font-weight: 700;
 font-size: 36px;
 margin-left: auto;
 margin-right: auto;
}

.Panel-subtitle {
 margin-top: 12px;
 font-weight: 500;
 font-size: 15px;
 line-height: 20px;
 color: #888686;
 margin-left: auto;
 margin-right: auto;
 margin-bottom: 50px;
}

.Panel-input {
 height: 64px;
 border: none;
 background: linear-gradient(0deg, #F5FAFF, #F5FAFF), rgba(255, 255, 255, 0.05);
 border-radius: 8px;
 font-weight: 400;
 font-size: 16px;
 padding-left: 22px;
 padding-right: 22px;
 margin-bottom: 32px;
}

::placeholder {
 color: #838FA0;
}

.Panel-recaptcha {
 display: flex;
 justify-content: center;
 margin-bottom: 32px;
}

.Panel-button {
 border: none;
 background-color: #FF5995;
 border-radius: 12px;
 height: 64px;
 font-weight: 600;
 font-size: 18px;
 line-height: 24px;
 color: #FFFFFF;
 cursor: pointer;
 margin-bottom: 12px;
}

.Panel-button:disabled {
 cursor: not-allowed;
 pointer-events: all !important;
}

.Panel-button:hover {
 background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FF5995;
 color: white;
}

.Panel-button:disabled {
 background: #FF5995;
 opacity: 0.5;
}

.Panel-tip {
 display: flex;
 flex-wrap: wrap;
 padding: 16px;
 padding-left: 32px;
 background-color: #F5FAFF;
 border-radius: 8px;
}

.Tip-icon {
 display: inline-block;
}

.Tip-content {
 margin-left: 8px;
 font-weight: 400;
 font-size: 14px;
 line-height: 20px;
 display: inline-block;
 color: #FF5995;
}

.Tip-sent {
 color: #1E2235;
 font-size: 14px;
 line-height: 20px;
}

.Tip-error {
 color: #FD335E;
 font-size: 14px;
 line-height: 20px;
}

a {
 color: #FF5995;
 text-decoration: none;
 font-weight: 600;
}

a:hover {
 cursor: pointer;
 text-decoration: none;
}

.Faucet-footer {
 padding-bottom: 32px;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 14px;
 line-height: 20px;
}

.Footer-tip {
 margin: auto;
 text-align: center;
 color: #888686;
}

.Highlight-tip {
 font-weight: 600;
 color: #FF5995;
}